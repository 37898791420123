/**
 * Bootstrap Variables
 *
 */
$progress-bar-bg:               $blue;
$progress-bg:                   $lightgray;
$progress-height:               8px;
$progress-border-radius:        0;

$spinner-width:                 25px;
$spinner-border-width:          2px;

$input-focus-box-shadow:        none;
$form-floating-padding-x:       20px;
$form-floating-label-transform: scale(.75) translateY(-.5rem) translateX(.5rem);
$form-select-indicator:         url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.16095 0.868835C7.44045 0.868835 7.59584 1.19215 7.42124 1.4104L4.11478 5.54347C3.98134 5.71028 3.72764 5.71028 3.5942 5.54347L0.287744 1.4104C0.113141 1.19215 0.268531 0.868835 0.548034 0.868835L7.16095 0.868835Z' fill='%231B2A31'/%3E%3C/svg%3E%0A");
$form-select-bg-size:           10px;
$form-select-focus-box-shadow:  none;