/**
 *  Smarty Controls
 *
 */

$class: '.smarty-results';

form #{$class} {
  display: none;
  flex-direction: column;

  position: absolute;
  top: 60px;
  left: 0;
  z-index: 20;

  width: 100%;
  height: fit-content;
  background: $white;
  border-radius: 4px;
  border: 1px solid $slate;
 
  &.results {
    display: flex;
    text-align: left;
  }

  li {
    user-select: none;
    padding: 10px 20px;
    border-bottom: 1px solid #B5BCBE;

    &:hover {
      cursor: pointer;
      background: $lightgray;
    }

    &:last-child {
      border: none;
    }
  }
}