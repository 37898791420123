/**
 * Main
 * 
 */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'colors';
@import 'typography';
@import 'buttons';
@import 'helpers';
@import 'global';

@import 'bootstrap-variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/maps';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/progress';
@import 'node_modules/bootstrap/scss/popover';
@import 'node_modules/bootstrap/scss/spinners';

// Address autocompletes
@import 'smarty-controls';
@import '@splidejs/splide/css';

@import 'pages/site-v2';
@import 'pages/congratulations';
@import 'pages/splide/quote-estimate';