/**
 * Colors
 *
 */
$blue:        #3367a1;
$red:         #cb3033;
$gray:        #333;
$lightgray:   #f7f8fc;
$slate:       #d9d9d9;
$placeholder: #9ca3af;
$blue-link:   #106cb9;
$blue-site:   #46a0ea;
$gray-site:   #eceaea;
$gray-header: #6e7276;
$black-header: #413832;
$dark-blue: #023469;
$off-black: #2a2a2a;
$button-blue: #009dd9;
$testimonial-gray: #eff3f5;