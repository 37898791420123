/**
 * Typography
 *
 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../../fonts/opensans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype-variations');
}

h1 {
  font-size: calc(0.00385 * 100vw + 26.76923px);
  letter-spacing: -1px;
  font-weight: 700;
  line-height: 2.5rem;
  color: $blue;
}

h2 {
  font-size: calc(0.00481 * 100vw + 26.46154px);
  letter-spacing: -2px;
  font-weight: 700;
  line-height: 2.5rem;
  color: $blue;

  span {
    display: block;
    font-size: calc(0.00288* 100vw + 19.07692px);
  }
}

h5 {
  font-size: 24px;
  font-weight: 600;
}