/**
 *  Partial: Slider
 *
 */
$class: '.slider.quote-estimate-slider';

#{$class} {
  ul {
    &.splide__pagination {
      bottom: -24px;

      button {
        width: 10px;
        height: 4px;
        border-radius: 100px;
        background: #6785A4;
        opacity: 1 !important;

        &.is-active {
          width: 20px;
          height: 4px;
          gap: 0px;
          border-radius: 100px;
          background: #FFFFFF;
        }
      }
    }
  }

  .trust-card {
    font-family: Poppins, Arial, sans-serif;
  }

  .splide__track {
    height: 100% !important;

    li {
      height: 100%;
    }

    .slide-card {
      font-family: 'Montserrat', sans-serif;
      height: 100%;
      gap: 16px;

      .stars {
        height: 100%;
      }
    }
  }
}
