/**
 * Congratulations
 *
 */
$class: '.page-congratulations';

//@import '@splidejs/splide/css';
//@import 'splide/success-slider.scss';

#{$class} {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;

    @include media-breakpoint-up(xl) {
      max-width: 1440px;
    }
  }

  header {
    background-image: url(../../img/bg.webp);
    background-size: cover;

    * {
      color: $white;
    }

    a {
      .call-button {
        font-size: 14px;
        background: $blue-site;
        color: $white;
        transition: all 0.1s ease;

        &:hover {
          background-color: darken($blue-site, 10%);
        }
      }
    }
  }

  h1 {
    line-height: 1.25 !important;
  }

  &-sidebar {
    @include media-breakpoint-down(md) {
      margin-left: -1rem;
      width: calc(100% + 20px);
      max-width: none;
    }
  }

  &-testimonial-box {
    @include media-breakpoint-down(md) {
      &-stat {
        width: 100%;
      }
    }

    @include media-breakpoint-up(md) {
      width: calc(100% + 150px);

      &-stat {
        right: 2.5rem;
        bottom: -32px;
      }
    }
  }
}