/**
 * Global
 *
 */
html {
  scroll-behavior: smooth;
  font-size: 16px;
}

html,
body {
  font-family: 'Open Sans', sans-serif;
}

:root {
  --animate-duration: 0.5s;
}

* {
  -webkit-font-smoothing: antialiased;
}

p {
  padding: 0 0 1rem;
}

span.lock {
  display: inline-block;

  &::after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'%3E%3Cpath d='M7.99999 10.0833L9.66666 11.75L13.4167 7.99999M17.1667 10.5C17.1667 14.5904 12.705 17.5653 11.0817 18.5124C10.8972 18.62 10.8049 18.6738 10.6747 18.7018C10.5737 18.7234 10.4263 18.7234 10.3253 18.7018C10.1951 18.6738 10.1028 18.62 9.91834 18.5124C8.29496 17.5653 3.83333 14.5904 3.83333 10.5V6.51466C3.83333 5.8484 3.83333 5.51527 3.9423 5.22891C4.03856 4.97594 4.19498 4.75023 4.39804 4.57127C4.62791 4.36869 4.93983 4.25172 5.56367 4.01778L10.0318 2.34222C10.2051 2.27725 10.2917 2.24477 10.3808 2.23189C10.4599 2.22047 10.5401 2.22047 10.6192 2.23189C10.7083 2.24477 10.7949 2.27725 10.9682 2.34222L15.4363 4.01778C16.0602 4.25172 16.3721 4.36869 16.6019 4.57127C16.805 4.75023 16.9614 4.97594 17.0577 5.22891C17.1667 5.51527 17.1667 5.8484 17.1667 6.51466V10.5Z' stroke='%23212529' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-size: contain;
    height: 20px;
    width: 20px;
    display: block;
    margin-right: 5px;
    transform: translate(0, 5px);
  }
}