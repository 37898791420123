/**
 * Site
 *
 */
$class: '.page-site-form-v2';

#{$class} {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;

    @include media-breakpoint-up(xl) {
      max-width: 1440px;
    }
  }

  header {
    a {
      .call-button {
        font-size: 14px;
        background: $blue-site;
        color: $white;
        transition: all 0.1s ease;

        &:hover {
          background-color: darken($blue-site, 10%);
        }
      }
    }

    button.back {
      @include media-breakpoint-down(md) {
        span {
          display: none;
        }
      }
    }
  }

  &-logos {
    background-image: url(../../img/bg.webp);
    background-size: cover;
  }

  form {
    h3 br {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .pristine-error {
      margin-top: 5px !important;
    }

    button.back,
    button[type=submit] {
      font-size: 20px;
      background: $blue-site;
      color: $white;
      min-height: 50px;
      font-weight: bold;
      transition: all 0.1s ease;

      @include media-breakpoint-up(md) {
        letter-spacing: -0.025em;
      }
      
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }

      svg {
        &.spinner {
          height: 36px;
        }
      }

      &:focus,
      &:focus-visible,
      &:hover {
        outline: none;
        background-color: darken($blue-site, 10%);

        svg:not(.spinner) {
          transform: translate(5px, 0);
        }
      }

      &:disabled {
        background: $slate;
        cursor: not-allowed;
      }
    }

    button.back {
      background-color: $white;
      border: 2px solid $slate;
    }

    label.form-label {
      color: $placeholder;
      font-weight: 500;
    }

    .form-floating > .form-control:focus ~ label,
    .form-floating > .form-control:not(:placeholder-shown) ~ label, 
    .form-floating > .form-control-plaintext ~ label, 
    .form-floating > .form-select ~ label {
      color: $placeholder;
    }

    input:not([type=checkbox]),
    select {
      position: relative;
      height: 60px;
      z-index: 1;
      padding: 0 20px;
      color: $black;
      font-weight: 600;

      appearance: none;
      border: 1px solid $slate !important;
      border-radius: 5px;
      outline: none;

      @include media-breakpoint-down(md) {
        //
      }
    }

    input:not([type=checkbox]) {
      background: none;

      &:disabled {
        background-color: $lightgray;
        cursor: not-allowed;

        label {
          opacity: 0.6;  
        }
      }
    }

    &.debt-step {
      @include media-breakpoint-down(md) {
        li {
          br {
            display: none;
          }
        }
      }
    }
  }
}