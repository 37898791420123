/**
 * Buttons
 *
 */
.button {
  margin: 0 auto;
  padding: 10px 20px;
  border: 2px solid $blue;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 700;
  word-spacing: -1px;
  transition: opacity 0.35s ease;
  white-space: nowrap;
  text-decoration: none;
  color: $blue;
  display: flex;
  align-items: center;
}

a {
  color: $blue;
  text-decoration: underline;
  font-weight: 600;
}